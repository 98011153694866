import { Box, Button, Center, Flex, Image, Stack, useToast } from '@chakra-ui/react'
import { Field, Formik, FormikValues } from 'formik'
import { useLoginMutation } from '../../api/graphql'
import { InputGroupForm } from '../../uikit/InputGroup'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import { InputMaskForm } from '../../uikit/InputMask'
import { Layout } from '../../shared/layouts/Layouts'
import React from 'react'
import Logo from 'shared/images/logo.svg'

const formSchema = Yup.object().shape({
  phone: Yup.string().required(''),
  password: Yup.string().required('')
})

export const Login = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const [ loginMutation, { loading: isLoading } ] = useLoginMutation()

  const handleForm = async (values: FormikValues) => {
    try {
      const phoneValue = `${values?.phone?.replace(/\D/g, '')}`
      await loginMutation({
        variables: {
          phone: phoneValue,
          password: values?.password
        }
      })
      navigate('/enter', { replace: true })
    } catch (e) {
      toast({
        title: `Ошибка авторизации`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  return (
    <Layout>
      <Center flex={1}>
        <Flex
          backgroundColor="white"
          maxW="420px"
          width="100%"
          borderRadius="20px"
          padding="40px 50px 50px"
          borderWidth={1}
        >
          <Stack
            width="100%"
            spacing="20px"
            textAlign="center"
          >
            <Stack spacing="16px" alignItems="center">
              <Flex width="100%" justifyContent="center" pb="28px" borderBottomWidth={1}>
                <Link to="/">
                  <Image maxW="120px" src={Logo} alt="Logo" />
                </Link>
              </Flex>
              <Box>
                Введите электронную почту и пароль или зарегистрируйтесь
              </Box>
            </Stack>
            <Formik
              validationSchema={formSchema}
              initialValues={{
                phone: '',
                password: ''
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Stack spacing="24px">
                    <Stack spacing="14px">
                      <Field
                        size="lg"
                        name="phone"
                        placeholder="Телефон"
                        component={InputMaskForm}
                        isDisabled={isLoading}
                      />
                      <Field
                        size="lg"
                        type="password"
                        name="password"
                        placeholder="Пароль"
                        component={InputGroupForm}
                        isDisabled={isLoading}
                      />
                    </Stack>
                    <Stack spacing="14px">
                      <Button
                        size="lg"
                        isLoading={isLoading}
                        type="submit"
                        variant="primary"
                      >
                        Войти
                      </Button>
                      <Button
                        size="lg"
                        variant="secondary"
                        onClick={() => navigate('/registration')}
                      >
                        Зарегистрироваться
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Stack>
        </Flex>
      </Center>
    </Layout>
  )
}
