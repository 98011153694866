import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocCookiesModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocCookiesModal: React.FC<DocCookiesModalProps> = ({ isOpen, onClose }) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <p>
        <strong>ПОЛИТИКА ИСПОЛЬЗОВАНИЯ ФАЙЛОВ COOKIE</strong> <strong></strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        <strong>1.</strong><strong>ОСНОВНЫЕ ПОЛОЖЕНИЯ</strong> <strong></strong>
      </p>
      <p>
        1.1. Политика использования на сайте (далее –
        «<strong>Сайт</strong>») компании ООО « » (далее –
        «<strong>Компания</strong>») файлов cookie (далее — «<strong>Политика</strong>»)
        определяет правила, по которым Компания собирает и обрабатывает информацию о
        пользователях сайта.
      </p>
      <p>
        1.2. Настоящая Политика является частью Политики конфиденциальности
        Компании.
      </p>
      <p>
        1.3. Политика описывает типы файлов cookie, цели их использования и
        способы, с помощью которых можно от них отказаться.
      </p>
      <p>
        1.4. Файлы cookie — это небольшие фрагменты данных с информацией о
        посещении Сайта, которые сервер отправляет на Ваше устройство. Файлы cookie
        запоминают информацию о ваших предпочтениях, позволяют в более удобном для
        Вас режиме просматривать посещенные сайты в течение определенного времени.
        Например, благодаря файлам cookie Сайт предлагает Вам просматривать
        информацию на том языке, которым Вы обычно пользуетесь.
      </p>
      <p>
        1.5. Компания может использовать файлы cookie для того, чтобы:
      </p>
      <ul>
        <li>
          помогать вам оставаться авторизованным на Сайте;
        </li>
        <li>
          улучшать качество вашей работы с Сайтом;
        </li>
        <li>
          показывать приоритетную для Вас информацию;
        </li>
        <li>
          сохранять ваши настройки предпочтений;
        </li>
        <li>
          отображать рекламу, которая может вас заинтересовать;
        </li>
        <li>
          анализировать статистику использования Сайта.
        </li>
      </ul>
      <p>
        1.6. На основании данных, полученных с помощью файлов cookie,
        Компания разрабатывает наиболее полезный функционал для Сайта, которым вы
        пользуетесь, проводит статистические и маркетинговые исследования,
        исправляет ошибки на Сайте и тестирует новые функции для повышения
        производительности Сайта, персонализирует и показывает наиболее релевантную
        для Вас информацию.
      </p>
      <p>
        1.7. Компания не допускает применения рекомендательных технологий,
        которые нарушают права и законные интересы граждан и организаций, а также не
        допускает применение рекомендательных технологий в целях предоставления
        информации с нарушением законодательства Российской Федерации. Под
        рекомендательными технологиями здесь и далее понимаются информационные
        технологии предоставления информации на основе сбора, систематизации и
        анализа сведений, относящихся к предпочтениям пользователей сети «Интернет»,
        находящихся на территории Российской Федерации».
      </p>
      <p>
        <strong>2.</strong><strong>КАКИЕ ФАЙЛЫ С</strong><strong>OOKIE</strong>
        <strong>ИСПОЛЬЗУЮТСЯ</strong>
      </p>
      <p>
        2.1. Компания использует различные типы файлов cookie. Основная
        задача в их использовании - это обеспечить удобство вашей работы с Сайтом,
        поддерживать высокий уровень безопасности в их работе, вести подсчет
        аудитории, помогать в проведении оплат, переходах по страницам и прочее. Все
        файлы cookie Компании относятся к одному из следующих типов:
      </p>
      <p>
        2.1.1. Технические файлы cookie, которые необходимы для правильной работы
        и предоставления полного функционала Сайта.
      </p>
      <p>
        2.1.2. Аналитические и маркетинговые файлы cookie, которые позволяют:
      </p>
      <ul>
        <li>
          распознавать пользователей Сайта, подсчитывать их количество и собирать
          информацию, например, о произведенных операциях, посещенных страницах и
          просмотрах на Сайте;
        </li>
        <li>
          идентифицировать ваше аппаратное или программное обеспечение, например,
          тип браузера или устройство;
        </li>
        <li>
          собирать информацию о том, как вы взаимодействуете с Сайтом, например,
          приобретены ли услуга или продукт;
        </li>
        <li>
          обеспечивать релевантность рекламы, повышать эффективность рекламных
          кампаний.
        </li>
      </ul>
      <p>
        2.1.3. Прочие файлы cookie выполняют различные служебные функции и делают
        удобнее работу с Сайтом и его функционалом. Эти файлы помогают запомнить
        состояние страницы, выбрать регион, сохранить персональные предпочтения и
        прочее.
      </p>
      <p>
        2.2. Компания может применять веб-маяки (пиксельные теги) для
        доступа к файлам cookie, ранее размещенным на вашем устройстве. Их
        использование необходимо для анализа ваших действий при работе с Сайтом
        путем доступа и использования файлов cookie, хранящихся на Вашем устройстве,
        для сбора статистики о работе Сайта.
      </p>
      <p>
        2.3. Компания может использовать на Сайте инструмент веб-аналитики
        Яндекс.Метрика, который позволяет собирать обезличенную информацию об
        источниках трафика, посещаемости Сайта и оценивать эффективность рекламы.
      </p>
      <p>
        2.4. Для учета посетителей с помощью сервиса Яндекс.Метрика
        используются анонимные идентификаторы браузеров, которые сохраняются в
        файлах cookie. Подробнее о файлах cookie Яндекс.Метрики можно прочитать на
        странице
        <a
          href="https://yandex.ru/support/metrica/general/cookie-usage.html"
          target="_blank"
        >
          https://yandex.ru/support/metrica/general/cookie-usage.html
        </a>
        .
      </p>
      <p>
        2.5. Компания также может использовать Инструмент веб-аналитики
        Google Analytics, который позволяет собирать обезличенную информацию об
        источниках трафика, посещаемости сайта и оценивать эффективность рекламы.
        Для учета посетителей Google Analytics использует анонимные идентификаторы
        браузеров, которые сохраняются в файлах cookie. Подробнее о файлах cookie
        Google Analytics можно прочитать на странице
        <a
          href="https://policies.google.com/technologies/partner-sites?hl=en-US"
          target="_blank"
        >
          https://policies.google.com/technologies/partner-sites?hl=en-US
        </a>
        .
      </p>
      <p>
        2.6. Отключение некоторых указанных типов файлов cookie может
        привести к тому, что использование отдельных разделов или функций Сайта
        станет невозможным.
      </p>
      <p>
        2.7. Доступ к разделам Сайта, для которых нужна авторизация,
        обеспечивается техническими файлами cookie и без их использования Сайт может
        работать некорректно.
      </p>
      <p>
        <strong>3.</strong> <strong>РЕКОМЕНДАТЕЛЬНЫЕ ТЕХНОЛОГИИ</strong>
      </p>
      <p>
        3.1. Для алгоритмических вычислений Компания может использовать
        данные, полученные от пользователей Сайта, а также техническую информацию,
        собираемую автоматически, в том числе информацию о том, как пользователь
        пользуется Сайтом и информацию об устройстве пользователя, в том числе (если
        применимо):
      </p>
      <ul>
        <li>
          данные о действиях пользователя на Сайте;
        </li>
        <li>
          данные о запросах пользователя на Сайте;
        </li>
        <li>
          IP адрес;
        </li>
        <li>
          файлы cookies;
        </li>
        <li>
          длительность пользовательской сессии;
        </li>
        <li>
          точки входа (сторонние сайты, с которых пользователь по ссылке переходит
          на сайт);
        </li>
        <li>
          точки выхода (ссылки на сайте, по которым пользователь переходит на
          сторонние сайты);
        </li>
        <li>
          страна пользователя;
        </li>
        <li>
          геопозиция;
        </li>
        <li>
          регион пользователя;
        </li>
        <li>
          часовой пояс, установленный на устройстве пользователя;
        </li>
        <li>
          провайдер пользователя;
        </li>
        <li>
          браузер пользователя;
        </li>
        <li>
          ОС пользователя;
        </li>
        <li>
          информация об устройстве пользователя;
        </li>
        <li>
          параметры экрана (разрешение, глубина цветности, параметры размещения
          страницы на экране);
        </li>
        <li>
          дата и время посещения сайта;
        </li>
        <li>
          источник перехода (UTM метка);
        </li>
        <li>
          данные, содержащиеся в Личном кабинете пользователя, зарегистрированного
          на Сайте;
        </li>
        <li>
          данные сетевого трафика.
        </li>
      </ul>
      <p>
        3.2. Компания использует рекомендательные технологии для
        формирования персональных и неперсональных подборок на Сайте, обработки
        запросов пользователей Сайта при поиске услуг в целях быстрого
        предварительного отбора услуг, из которых пользователь Сайта может выбрать
        наиболее подходящие.
      </p>
      <p>
        3.3. Алгоритмы Сайта применяются с целью подобрать для пользователя
        Сайта наиболее релевантные услуги на основании его действий на Сайте.
      </p>
      <p>
        3.4. Процесс работы рекомендательных технологий Компании заключается
        в следующем:
      </p>
      <ul>
        <li>
          формируется набор данных о последней активности пользователя;
        </li>
        <li>
          на основании этого набора делается предсказание его последующих действий
          набором алгоритмов;
        </li>
        <li>
          результаты работы нескольких алгоритмов объединяются и могут формировать
          рекомендации, подходящие пользователю;
        </li>
        <li>
          на основании схожести характеристик услуг и информации о пользователе
          отобранные рекомендации могут досортировываются и сохраняться для выдачи
          пользователю;
        </li>
        <li>
          на этапе выдачи услуг заранее рассчитанные рекомендации перемешиваются в
          соответствии с бизнес-правилами.
        </li>
      </ul>
      <p>
        <strong>4.</strong><strong>ХРАНЕНИЕ ФАЙЛОВ</strong><strong>COOKIE</strong>
        <strong>НА ВАШЕМ УСТРОЙСТВЕ</strong>
      </p>
      <p>
        4.1. Компания использует сессионные файлы cookie, чтобы Вам было
        удобнее работать с Сайтом. Например, могут запоминаться данные Вашей
        авторизации на Сайте и при переходе в другую доменную зону уже не нужно
        заново проходить процесс авторизации. Срок действия сессионных файлов cookie
        истекает в конце сессии (когда вы закрываете страницу или окно браузера).
      </p>
      <p>
        4.2. Компания также может использовать файлы cookie, которые
        сохраняются в течение более длительного периода, например, чтобы запомнить
        ваши предпочтения на Сайте, такие как язык или местоположение.
      </p>
      <p>
        4.3. Срок хранения данных зависит от типа файлов cookie.
      </p>
      <p>
        4.4. Файлы cookie будут автоматически удалены после того, как
        выполнят свою задачу.
      </p>
      <p>
        4.5. Компания использует информацию, содержащуюся в файлах cookie в
        течение сроков и на условиях, указанных в настоящей Политике, а также в
        Политике конфиденциальности.
      </p>
      <p>
        <strong>5.</strong><strong>УПРАВЛЕНИЕ</strong><strong>COOKIE</strong>
        <strong>ФАЙЛАМИ</strong>
      </p>
      <p>
        5.1. При первом посещении Сайта во всплывающем окне (либо с помощью
        другого технического решения) Вам может быть предложено принять типы файлов
        cookie, которые будут записаны на вашем устройстве.
      </p>
      <p>
        5.2. При этом, технические файлы cookie устанавливаются
        автоматически при загрузке страницы, если иное не указано в настройках
        браузера.
      </p>
      <p>
        5.3. Если Вы одобрили использование файлов cookie, но потом захотели
        изменить свое решение, то сделать это можно самостоятельно, удалив
        сохраненные файлы в Вашем браузере.
      </p>
      <p>
        5.4. Компания не требует обязательного согласия на установку файлов
        cookie на ваше устройство при использовании Сайта.
      </p>
      <p>
        5.5. Если вы не хотите, чтобы файлы cookie сохранялись на вашем
        устройстве, отключите эту опцию в настройках браузера.
      </p>
      <p>
        5.6. Сохраненные файлы cookie также можно удалить в любое время в
        системных настройках браузера.
      </p>
      <p>
        5.7. Вы можете изменить настройки браузера, чтобы принимать или
        отклонять по умолчанию все файлы cookie либо файлы cookie с определенных
        сайтов, включая Сайт.
      </p>
      <p>
        5.8. Если Вы одобрили использование файлов cookie на одной из
        страниц Сайта, это будет означать, что такое согласие дано вами для
        использования файлов cookie на всех страницах Сайта включая Личный кабинет.
      </p>
      <p>
        <strong>6.</strong> <strong>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
      </p>
      <p>
        6.1. Информация, которая собрана с помощью файлов cookie,
        размещенных на Вашем устройстве, хранится в соответствии с применимым
        законодательством согласно условиям настоящей Политики и Политики
        конфиденциальности.
      </p>
      <p>
        6.2. Информация, собранная с помощью файлов cookie, размещенных на
        Вашем устройстве, может быть передана и доступна Компании и/или партнерам на
        условиях Политики конфиденциальности.
      </p>
      <p>
        6.3. Использование вне Сайта собранной информации может быть
        предметом отдельных пользовательских соглашений доступных на Сайте.
      </p>
      <p>
        6.4. Компания может также предоставить вам возможность отказаться от
        персонализации рекламы, в соответствии с законодательством и применимым
        правилами.
      </p>
      <p>
        <strong>Дата публикации</strong><strong>редакции настоящей Политики</strong><strong>:</strong><strong>
        </strong><strong>.</strong><strong>08</strong><strong>.202</strong><strong>4</strong>
        <strong>г.</strong>
      </p>
    </DocumentModal>
  )
}
