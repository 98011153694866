import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Container } from '../../components/Container'
import Logo from 'shared/images/logo.svg'
import Hero from './images/hero.png'
import Bg from './images/bg.svg'
import { ButtonPromo } from '../../components/ButtonPromo'
import { HeadingPromo } from '../../components/HeadingPromo'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../../../../../state/useCurrentUser'

export const Intro = () => {
  const { isAuthenticated } = useCurrentUser()

  const navigate = useNavigate()
  const gradient =
    'linear-gradient(111deg, rgba(255, 255, 255, 0.00) 1.82%, rgba(180, 188, 255, 0.37) 50.91%, #F2F3E6 100%)'
  return (
    <Flex
      position="relative"
      width="100%"
      height="100%"
      minH="680px"
      background={gradient}
      borderBottomLeftRadius="40px"
      borderBottomRightRadius="40px"
    >
      <Container py="30px" position="relative" zIndex={1}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDirection={['column', 'column', 'row']}
          gap="24px"
        >
          <Image maxW="200px" src={Logo} alt="Logo" />
          {isAuthenticated ? (
            <Button
              variant="outline"
              colorScheme="purple"
              onClick={() => navigate('/enter')}
            >
              Панель управления
            </Button>
          ) : (
            <HStack>
              <Button
                variant="outline"
                colorScheme="purple"
                onClick={() => navigate('/enter')}
              >
                Вход
              </Button>
              <Button colorScheme="purple" onClick={() => navigate('/registration')}>
                Регистрация
              </Button>
            </HStack>
          )}
        </Flex>
        <Grid templateColumns={['1fr', '1fr', '1fr 420px', '1fr 520px']} width="100%" flex={1}>
          <Flex py="60px" gap="30px" flexDirection="column" justifyContent="space-between" width="100%">
            <Stack spacing="20px" textAlign={['center', 'center', 'left', 'left']}>
              <HeadingPromo size={['lg', 'xl', '2xl', '2xl']}>
                Сервис быстрых и безопасных переводов денежных средств в Китай
              </HeadingPromo>
              <Text fontSize="xl" fontWeight={300}>
                Оплата инвойсов для юридических лиц и индивидуальных
                предпринимателей
              </Text>
            </Stack>
            <Stack spacing="16px" alignItems={['center', 'center', 'flex-start', 'flex-start']}>
              <Text fontWeight={300}>Срок перевода от 5 рабочих дней</Text>
              <ButtonPromo
                width="fit-content"
                onClick={() => navigate('/enter')}
              >
                Оставить заявку
              </ButtonPromo>
            </Stack>
          </Flex>
          <Flex alignItems="center" justifyContent={['center', 'center', 'flex-end', 'flex-end']}>
            <Image maxW={['320px', '400px', '420px', '520px']} src={Hero} alt="Logo" />
          </Flex>
        </Grid>
      </Container>
      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        width="100%"
        height="100%"
        backgroundImage={`url(${Bg})`}
        backgroundPosition="top right"
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        opacity={0.5}
      />
    </Flex>
  )
}
