import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'
import { Icon } from '@chakra-ui/icon'
import { HiMiniArrowUpRight } from 'react-icons/hi2'

interface ButtonPromoProps extends ButtonProps {
  children: string
}

export const ButtonPromo: React.FC<ButtonPromoProps> = ({ children, ...props }) => {
  return (
    <Button
      size="lg"
      background="linear-gradient(137deg, #9997EE 9%, #5F3BB0 103.5%)"
      boxShadow="0px 3px 0px 0px #5F45A9"
      fontSize="1.1rem"
      color="white"
      borderRadius="12px"
      rightIcon={<Icon boxSize="20px" as={HiMiniArrowUpRight}/>}
      colorScheme="purple"
      backgroundSize="200% 100%"
      backgroundPosition="0"
      transition=".5s"
      _hover={{
        backgroundPosition: '50% 0',
      }}
      _active={{
        backgroundPosition: '100% 0',
        boxShadow: '0px 2px 0px 0px #4C3494',
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
