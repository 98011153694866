import React from 'react'
import { Layout } from './shared/layouts/Layouts'
import { Route, Routes } from 'react-router-dom'
import { Login } from './routes/Login'
import { useApollo } from './config/apollo'
import { ApolloProvider } from '@apollo/client'
import { ScrollToTop } from './components/ScrollToTop'
import { Registration } from './routes/Registration'
import { Main } from './routes/Main'
import { Logout } from './routes/Logout'

function App() {
  const client = useApollo()

  return (
    <ApolloProvider client={client}>
      <ScrollToTop>
        <Routes>
          <Route path="/*" element={<Main />} />
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />
          <Route path="registration" element={<Registration />} />
        </Routes>
      </ScrollToTop>
    </ApolloProvider>
  )
}

export default App
